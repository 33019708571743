app.controller('IndexCtrl', function($localStorage,$scope,$rootScope,$http,$timeout,$interval,$stateParams,$q,$sce,data,SocketService) { 
console.log(data);
var animation = null;
$scope.animations={
	sides:'',
	ad:'fadeIn delay-4s',
	home:'fadeIn',
	result:'fadeIn',
	away:'fadeIn',
	innings:'fadeIn',
	location:'fadeIn',
	events:{
		goal:''
	}
};

$scope.scorer=null;
SocketService.connect().then(function(){
	SocketService.socket.on('data_changed',function(data){
		if(data.is_goal === true){
			$scope.scorer=data.goal_data.mannschaftname;
			$scope.goal(data.goal_data.lt_mannschaft_idMannschaft).then(function(){
				reload();
				// $scope.$apply();
			});
		}else{
		  reload();
		}
		
	});
});

function reload(){
	$http.get(config.site_url+'/liveticker/v1/data').then(function(result){
		set_values(result.data,false);
		
	},function(err){			
	
	});
}

set_values(data,true);




$scope.location='Sparkassen Eisdom Halle';
$scope.screen_active=true;

function set_values(datas,results){
	if(results===true){
		$scope.results={
			home:datas.score.home,
			away:datas.score.away,
			innings:{
				one:{
					home:datas.score.home_first,
					away:datas.score.away_first,
					started:true
				},
				two:{
					home:datas.score.home_second,
					away:datas.score.away_second,
					started:true
				},
				three:{
					home:datas.score.home_third,
					away:datas.score.away_third,
					started:true
				},
				overtime:{
					home:datas.score.home_overtime,
					away:datas.score.away_overtime,
					started:false
				}
			}
		}
	}else{
		$scope.results.innings={
				one:{
					home:datas.score.home_first,
					away:datas.score.away_first,
					started:true
				},
				two:{
					home:datas.score.home_second,
					away:datas.score.away_second,
					started:true
				},
				three:{
					home:datas.score.home_third,
					away:datas.score.away_third,
					started:true
				},
				overtime:{
					home:datas.score.home_overtime,
					away:datas.score.away_overtime,
					started:false
				}
			}	
	}
	$scope.spieltag=datas.spieltag;
	$scope.aufstellung_home = datas.aufstellung_home;
	$scope.aufstellung_away = datas.aufstellung_away;
	$scope.spielgeschehen = datas.spielgeschehen;
	if($scope.spielgeschehen[0].drittel==='0'){
	$scope.tab = 'warm_up';
	}else if($scope.spielgeschehen[0].drittel==='1'){
		$scope.tab = 'first_inning';
	}else if($scope.spielgeschehen[0].drittel==='2'){
		$scope.tab = 'second_inning';
	}else if($scope.spielgeschehen[0].drittel==='3'){
		$scope.tab = 'third_inning';
	}else if($scope.spielgeschehen[0].drittel==='4'){
		$scope.tab = 'overtime';
	}

	
}
$scope.ads=[
{
	src:'ad2.png'
},
{
	src:'ad3.png'
},
{
	src:'ad.png'
},
];

$scope.src='ad.png';
$scope.eventsrc='goal.gif';

$timeout(function(){
	loadAds();
},10000);
$scope.goal=function(id){
	var defer = $q.defer();
	if(animation===null){
		$scope.animations.events.goal='fadeIn';	
		$scope.animations.sides='fadeOut';
		
		$timeout(function(){
			$scope.screen_active=false;
			$timeout(function(){
			var audio = new Audio('audio/audio.mp3');
			audio.play();
			});
			goalAnimation(id).then(function(){
				defer.resolve(true);
			});
		},2000);
	}else{
		defer.resolve(true);
	}
	return defer.promise;
}
function loadAds(){
	$timeout(function(){
		var i = 0;
		$interval(function(){
			$scope.animations.ad = 'fadeOut';
			$timeout(function(){
				$scope.animations.ad='fadeIn';
				$scope.src=$scope.ads[i].src;
				if(i+1 >= $scope.ads.length){
					i--;	
				}else{
					i++;
				}
			},1000);
		},10000);
	},1000);
}
function goalAnimation(id){
var defer = $q.defer();	
var ml4 = {};
ml4.opacityIn = [0,1];
ml4.scaleIn = [0.2, 1];
ml4.scaleOut = 3;
ml4.durationIn = 800;
ml4.durationOut = 600;
ml4.delay = 500;

animation = anime.timeline()
  .add({
    targets: '.ml4 .letters-1',
    opacity: ml4.opacityIn,
    scale: ml4.scaleIn,
    duration: ml4.durationIn
  }).add({
    targets: '.ml4 .letters-1',
    opacity: 0,
    scale: ml4.scaleOut,
    duration: ml4.durationOut,
    easing: "easeInExpo",
    delay: ml4.delay
  }).add({
    targets: '.ml4 .letters-2',
    opacity: ml4.opacityIn,
    scale: ml4.scaleIn,
    duration: ml4.durationIn,
	begin:function(){
		
		$timeout(function(){
			if($scope.spieltag.lt_mannschaft_idMannschaft_gast==id){
				$scope.results.away ++; 
			}else{
				$scope.results.home ++;
			}
				
		},1400);
	}
  }).add({
    targets: '.ml4 .letters-2',
    opacity: 0,
    scale: ml4.scaleOut,
    duration: ml4.durationOut,
    easing: "easeInExpo",
    delay: 3000
  });
animation.finished.then(function(){
	//then, fade Out goal screen
		$scope.animations.sides='';
		$timeout(function(){
		$scope.animations.events.goal='fadeOut';	
		//set screen to true
		$scope.screen_active=true;
			//then, fadeIN normal screen
			$timeout(function(){
			defer.resolve(true);
			animation=null;
			},1000)
		},1000);
	
});
return defer.promise;
}
});