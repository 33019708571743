var app = angular.module('app', [
'ngStorage',
'ui.router',
// 'ngAnimate',
'ngCacheBuster'
]);

var domain='https://saalebulls.com';
var socket_domain = 'https://tickerv2.saalebulls.com'; 
// var path='http://localhost/liveticker';
var load;
var config={
site_url:domain+"/wp-json",
// base_url:domain+"/wp-content/plugins/liveticker/app/"

};

app.config(function(httpRequestInterceptorCacheBusterProvider,$httpProvider,$stateProvider,$rootScopeProvider,$urlRouterProvider,$transitionsProvider) {
   // httpRequestInterceptorCacheBusterProvider.setMatchlist([
   // // /.*liveticker\/v1\/.*/,
   // ]); // cache everthing  inside app/www/ Folder except ListItem.html 
   $stateProvider.  
   state('app', {
		cache: false,
		url: '/app',
		name: 'app',
		controller: 'IndexCtrl',
		templateUrl: 'liveticker.html',
		resolve:{
			data:function ($http,$q){
				var defer=$q.defer();
				$http.get(config.site_url+'/liveticker/v1/data').then(function(result){
							defer.resolve(result.data);
						},function(err){
							defer.reject(err);
				});
				return defer.promise;
			}
		}
   });
   
	
	
	$urlRouterProvider.otherwise('app');

	$httpProvider.interceptors.push( [ '$q','$localStorage','$state','$injector','$timeout','$rootScope', function( $q,$localStorage,$state,$injector,$timeout,$rootScope ) {
      return {
        'request': function( configuration ) {
          return configuration;
        },
		'requestError':function(response){
			 $timeout.cancel(load);
			 load=$timeout(function(){$rootScope.loading=false;},800);
			 return $q.reject(response);
		},
		'response':function(response){
		      $timeout.cancel(load);
			  load=$timeout(function(){$rootScope.loading=false;},800);
			return response;
		},
		'responseError':function(response){
			   $timeout.cancel(load)
			   load=$timeout(function(){$rootScope.loading=false;},800);
			return $q.reject(response);
		}
		
      };
    }]);
});

app.run(function($rootScope,$timeout,$state,$stateParams,$transitions,$localStorage,$window,$q,$http,SocketService) {
	
	

});
