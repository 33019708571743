app.factory('SocketService',function($http,$q,$state,$rootScope,$timeout,$localStorage) {
    var defer=$q.defer();
    var factory={}; 
	factory.connect=function() {
		var defer = $q.defer();
		factory.socket = io.connect(socket_domain+":3002",{secure: true, rejectUnauthorized : false});
		factory.socket.on('connect',function(){
			
		});
		defer.resolve(true);
		return defer.promise;
	}
	factory.disconnect=function(){
		// factory.socket.removeAllListeners();
		factory.socket.disconnect();
	}
	
    return factory;
});